@mixin notification-theme($notifications-theme) {
    $default-color: map-get($notifications-theme, default);
    $info-color: map-get($notifications-theme, info);
    $success-color: map-get($notifications-theme, success);
    $warn-color: map-get($notifications-theme, warning);
    $error-color: map-get($notifications-theme, error);

    .default-notification-overlay,
    .info-notification-overlay,
    .success-notification-overlay,
    .warning-notification-overlay,
    .error-notification-overlay {
        border-left: 4px solid;

        &::before {
            font-family: "Material Icons";
            float: left;
            font-size: 24px;
            // because we want spaces to be considered in content
            // https://stackoverflow.com/questions/5467605/add-a-space-after-an-element-using-after
            white-space: pre;
        }
    }

    .default-notification-overlay {
        border-left-color: $default-color;
        &::before {
            color: rgba(0, 0, 0, 0.466);
        }
    }

    .info-notification-overlay {
        border-left-color: $info-color;
        background-color: #17a2b8 !important;

        &::before {
            content: "\e88e  ";
            color: $info-color;
        }
    }

    .success-notification-overlay {
        border-left-color: $success-color;
        background-color: #28a745 !important;

        &::before {
            content: "\e86c  ";
            color: $success-color;
        }
    }

    .warning-notification-overlay {
        border-left-color: $warn-color;
        background-color: #ffc107 !important;
        color: black !important;
        &::before {
            content: "\e002  ";
            color: $warn-color;
        }
    }

    .error-notification-overlay {
        border-left-color: $error-color;
        background-color: #dc3545 !important;

        &::before {
            content: "\e000  ";
            color: $error-color;
        }
    }
}
