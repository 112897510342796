.blitzer{

    .pq-theme .ui-button .ui-button-text {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .pq-theme .ui-widget-header {
        border-color: #ff9898;
      }
      .pq-theme .ui-state-default,
      .pq-theme .ui-button {
       // background-image: linear-gradient(#fefefe, #cc0000);
        background: #eeeeee;
      }
      .pq-theme .ui-state-focus {
        background: #cc0000;
        border-color: #ff6666;
      }
      .pq-theme .ui-state-hover,
      .pq-theme .ui-button:hover {
        background: #fff;
        //border-color: #ff6666;
      }
      .pq-theme .ui-widget-header .ui-state-default:not(.ui-state-hover) {
        border-color: #ccc;
      }
      .pq-theme .ui-state-active {
       // background: #ff9898;
        border-color: #ff6666;
      }
      .pq-theme .ui-widget-content {
        border-color: #ff9898;
        background: #fff;
      }
      div.pq-theme {
        color: #333;
        border-color: #ccc !important;
        background: #cc0000;
      }
      .pq-theme *:focus {
        outline: 1px solid #ff6666;
      }
      .pq-theme select,
      .pq-theme input[type="text"],
      .pq-theme input,
      .pq-theme textarea,
      .pq-theme button.ui-state-default:not(.ui-state-hover),
      .pq-theme .ui-button {
        border-color: #ccc;
      }
      .pq-cont-lt,
      .pq-cont-tr,
      .pq-body-outer .pq-cont-left {
        border-color: #ff6666;
      }
      .pq-group-item {
        border-color: #ccc;
      }
      .pq-group-item:hover {
        border-color: #ff6666;
      }
      .pq-group-remove:hover,
      .pq-group-icon:hover,
      .pq-icon-hover:hover {
        border-color: #ff6666;
      }
      .pq-theme > .pq-grid-top {
        background: transparent;
        color: #fff;
        border-color: #ccc;
      }
      .pq-grid-title,
      .pq-group-header {
        background: #cc0000;
        border-color: #ccc;
      }
      div.pq-toolbar {
        border-color: #ccc;
      }
      .pq-theme span.pq-separator {
        border-right-color: #fff;
        border-left-color: #ff9898;
      }
      div.pq-pager,
      div.pq-grid-summary {
        border-top-color: #ccc;
      }
      .pq-grid-bottom {
        background: transparent;
        color: #fff;
      }
      .pq-header-outer {
        background: #cc0000;
        border-bottom-color: #ccc;
      }
      .pq-summary-outer {
        border-color: #ccc;
      }
      .pq-header-outer .pq-cont-inner {
        //background-image: linear-gradient(#fefefe, #cc0000);
        background: #cc0000 !important;
      }
      .pq-grid-cell,
      .pq-grid-row {
        border-color: #ccc;
      }
      .pq-td-border-right > .pq-grid-row > .pq-grid-cell {
        border-right-color: #ccc;
      }
      .pq-td-border-top > .pq-grid-row > .pq-grid-cell,
      .pq-cont-inner > .pq-td-border-top > .pq-grid-row {
        border-bottom-color: #ccc;
      }
      .pq-grid-row > .pq-grid-number-cell,
      .pq-grid-number-col {
        border-color: #ccc;
      }
      .pq-body-outer .pq-grid-number-cell,
      .pq-summary-outer .pq-grid-number-cell {
        background: #cc0000;
      }
      .pq-grid-col {
        border-color: #ccc;
      }
      .pq-col-sort-asc,
      .pq-col-sort-desc {
      //  background: #ffb3b3;
        border-color: #ff6666;
      }
      .pq-grid-col,
      .pq-grid-number-col {
        color: #fff;
      }
      .pq-grid-col:hover {
        background: #cc0000;
      }
      .pq-grid-row {
        background: #fff;
        color: #333;
      }
      .pq-state-select.ui-state-highlight {
        background: #d1eded;
      }
      .pq-grid-overlay {
        background: rgba(0, 0, 0, 0.1);
        border-color: #ff6666;
      }
      .pq-number-overlay,
      .pq-head-overlay {
        background: rgba(102, 255, 255, 0.25);
      }
      .pq-grid-cell-hover.ui-state-hover,
      .pq-grid-row-hover.ui-state-hover {
        background: #d1eded;
      }
      .pq-grid-row.pq-striped {
      //  background: #fff0f0;
      }
      .pq-state-select.pq-striped,
      .pq-striped > .pq-grid-cell-hover,
      .pq-grid-row-hover.pq-striped {
        background: #d1dfdf;
      }
      .pq-loading-bg {
        background: #cc0000;
      }
      .pq-loading > .pq-loading-mask {
        background: #cc0000;
        border-color: #ff6666;
        color: #333;
      }
      div.pq-loading-mask > div {
        border-color: #ff9898;
        background-color: #fff;
      }
      .pqg-vert-frozen-line,
      .pqg-horiz-frozen-line {
        border-color: #ff6666;
      }
      textarea.pq-cell-editor {
        box-shadow: inset 0 0 0 2px #ff6666;
      }
      .pq-border-0 {
        border-color: #ff6666;
      }
      .pq-border-1 {
        border-color: #ff9898;
      }
      .pq-border-2 {
        border-color: #ccc;
      }
      .pq-bg-0 {
        background-color: #ff6666;
      }
      .pq-bg-1 {
        background-color: #ff9898;
      }
      .pq-bg-2 {
        background-color: #ccc;
      }
      .pq-bg-3 {
        background-color: #cc0000;
      }




.pq-grid-disable{
    background:black !important;
    z-index:3}


}