.cupertino{
    .pq-grid .ui-button .ui-button-text {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .pq-grid .ui-widget-header {
       // background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        border-color: #d7d7d7;
      }

      .pq-theme .ui-widget-header .ui-state-default:not(.ui-state-hover) {
        border-color: #d7d7d7;
      }
      .pq-grid .ui-state-default {
        background-color: #fefefe;
        background-image: linear-gradient( #fefefe, #deedf7);
      }
      .pq-grid .ui-state-focus {
       // background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        border-color: #d7d7d7;
      }
      .pq-grid .ui-state-hover {
        //background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        border-color: #d7d7d7;
      }
      .pq-grid .ui-widget-header .ui-state-default:not(.ui-state-hover) {
       // border-color: #e0caca;
      }
      .pq-grid .ui-state-active {
        //background: ;
        border-color: #d7d7d7;
      }
      .pq-grid .ui-widget-content {
        border-color: #d7d7d7;

      }
      div.pq-grid {
        color: #333;
        border-color: #d7d7d7;
        //background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
      }
      .pq-grid *:focus {
        outline: 1px dotted #ccc;
      }
      .pq-grid select,
      .pq-grid input[type="text"],
      .pq-grid input,
      .pq-grid textarea,
      .pq-grid button.ui-state-default:not(.ui-state-hover) {
        border-color: #d7d7d7;
      }
      .pq-group-item {
        border-color: #d7d7d7;
      }
      .pq-group-item:hover {
        border-color: #d7d7d7;
      }
      .pq-group-remove:hover,
      .pq-group-icon:hover {
        border-color: #d7d7d7;
      }
      .pq-grid > .pq-grid-top {
        //background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        color: #333;
        border-color: #d7d7d7;
      }
      .pq-grid-title,
      .pq-group-header {
        //background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        border-color: #d7d7d7;
      }
      div.pq-toolbar {
        border-color: #d7d7d7;
      }
      .pq-grid span.pq-separator {
        border-right-color: #fff;
        border-left-color: #d7d7d7;
      }
      div.pq-pager,
      div.pq-grid-summary {
        border-top-color: #aed0ea;
      }
      .pq-grid-bottom {
        background: #ccc;
        color: #333;
      }
      .pq-header-outer {
        background: #ccc;
        border-bottom-color: #aed0ea;
      }
      .pq-header-outer > .pq-grid-header {
        //background: #ccc;
        background-image: linear-gradient( #fefefe, #deedf7);
        background-color: #fefefe;
        //background-image: linear-gradient( #fefefe, #ccc);
      }
      .pq-grid-table > tbody > tr > .pq-grid-cell {
        border-color: #d7d7d7;
      }
      .pq-grid-row > td.pq-grid-number-cell,
      .pq-grid-number-col {
        border-color: #d7d7d7;
      }
      .pq-grid-row > .pq-grid-number-cell {
        background: #f2f5f7;
      }
      .pq-grid-col {
       // border-color: #e0caca !important;
      }
      .pq-col-sort-asc,
      .pq-col-sort-desc {
        background: #dec7c7;
        border-color: #d7d7d7;
      }
      .pq-grid-col,
      .pq-grid-number-col {
        color: #333;
      }
      .pq-grid-col:hover {
        background: #ccc;
      }
      .pq-grid-row {
        background: #f2f5f7;
        color: #333;
      }
      .pq-hvscroll-square {
        background: #ccc;
      }
      .pq-state-select.ui-state-highlight {
        background: #dde5e5;
      }
      tr.pq-grid-oddRow {
        background: #ffeeee;
      }
      .pq-grid-cell-hover.ui-state-hover,
      .pq-grid-row-hover.ui-state-hover {
        background: #f2f5f7;
      }
      tr.pq-grid-oddRow > td.pq-state-select,
      tr.pq-grid-oddRow.pq-state-select,
      tr.pq-grid-oddRow > .pq-grid-cell-hover,
      tr.pq-grid-oddRow.pq-grid-row-hover {
        background: #f2f5f7;
      }
      .pq-grid-cell:focus {
        outline: 2px solid #777;
      }
      .pq-loading-bg {
        background: #ccc;
      }
      .pq-loading > .pq-loading-mask {
        background: #ccc;
        border-color: #d7d7d7;
        color: #333;
      }
      div.pq-loading-mask > div {
        border-color: #d7d7d7;
        background-color: #fff;
      }
      .pqg-vert-frozen-line,
      .pqg-horiz-frozen-line {
        border-color: #d7d7d7;
      }
      .pq-sb-horiz-t .pq-sb-slider,
      .pq-sb-vert-t .pq-sb-slider,
      .pq-sb-horiz-t .pq-sb-btn,
      .pq-sb-vert-t .pq-sb-btn {
        border-color: #d7d7d7;
        background: #ccc;
      }
      .pq-sb-horiz-t > .pq-sb-slider:hover,
      .pq-sb-vert-t > .pq-sb-slider:hover,
      .pq-sb-horiz-t > .pq-sb-btn:hover,
      .pq-sb-vert-t > .pq-sb-btn:hover {
        border-color: #d7d7d7;
        background: #dec7c7;
      }

      .pq-header-outer .pq-cont-inner {
        background-image: linear-gradient( #fefefe, #deedf7);
        border-bottom: #aed0ea;
      }
      .pq-theme .ui-state-default,
      .pq-theme .ui-button {
        background-image: linear-gradient( #fefefe, #deedf7);
      }

      .pq-theme .ui-state-hover,
        .pq-theme .ui-button:hover {
          background: #f2f5f7;
        }

        .pq-grid-col:hover {
          background: #f2f5f7;
        }
}