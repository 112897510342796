@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/rtl";
@import "app/theme/styles/helper";
@import "app/custom-styles.scss";

//paramquery grid css

@import "jquery-ui-pack/jquery-ui.css";
@import "jquery-ui-pack/jquery-ui.structure.css";

@import "jquery-ui-pack/jquery-ui.theme.css";

@import url('https://unpkg.com/pqgrid@8.6.0/pqgrid.min.css');
@import url('https://unpkg.com/pqgrid@8.6.0/pqgrid.ui.min.css');
@import url('https://unpkg.com/pqgrid@8.6.0/themes/bootstrap/pqgrid.css');
// @import "pqgrid/pqgrid.min.css";
// @import "pqgrid/pqgrid.ui.min.css";
// @import "pqgrid/themes/bootstrap/pqgrid.css";

// @import "pqgrid/themes/bootstrap/pqgrid.rtl.css";
@import "./app/custom-styles/notification.scss";

@import "./grid-themes/sunny.scss";
@import "./grid-themes/chocolate.scss";
@import "./grid-themes/blitzer.scss";

@import "./grid-themes/cupertino.scss";


// Theme for notifications / snackbar
$notifications-theme: (
  default: #fff,
  info: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette), 100),
  success: mat.get-color-from-palette(mat.define-palette(mat.$green-palette), 100),
  warning: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette), 200),
  error: mat.get-color-from-palette(mat.define-palette(mat.$red-palette), 200),
);
// import custom componenet themes
@include notification-theme($notifications-theme);

a:hover {
  color: #0056b3;
  text-decoration: none !important;
}

#main-content {
  padding-top: 0px !important;
}

.custom-modalbox {
  background-color: #000 !important;
}

textarea {
  width: 100%;
  resize: vertical;
  max-height: 300px;
}



*:focus {
  outline: 0 !important;
}

.bank-notes {
  background-color: #a44474 !important;
  color: #fff !important;
}

.reference {
  background: #fde894;
  color: #333;
}
